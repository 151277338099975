<template>
    <div class="h-full w-full flex">
        <router-view/>
        <BottomBanner />
    </div>
</template>

<script>
import {computed, onBeforeMount, onMounted, ref, watch} from 'vue'
import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {
    MenuAlt2Icon,
    PlusSmIcon,
    XIcon,
    BellIcon
} from '@heroicons/vue/outline'
import {SearchIcon} from '@heroicons/vue/solid';
import logo from "/logo.svg";
import useGameStore from "../modules/games/useGameStore";
import {useRoute} from "vue-router";
import _ from "lodash";
import useProductStore from "../modules/products/useProductStore";
import useLocalStorageStore from "../utils/useLocalStorageStore";
import Footer from "./components/Footer.vue";
import DarkModeToggle from "../components/DarkModeToggle.vue";
import {sendFathomEvent} from "../modules/fathom/fathomUtils";
import LanguageSelector from "./components/LanguageSelector.vue";
import useMiscInformationStore from "../utils/useMiscInformationStore";
import SearchResult from "./components/SearchResult.vue";
import NotificationPanel from "./components/NotificationPanel.vue";
import BottomBanner from "./components/BottomBanner.vue";
import BackToTopButton from "../components/BackToTopButton.vue";
import StorefrontIcon from "../components/StorefrontIcon.vue";

let sidebarNavigation = [
]

export default {
    components: {
        StorefrontIcon,
        BackToTopButton,
        BottomBanner,
        NotificationPanel,
      SearchResult,
        LanguageSelector,
        DarkModeToggle,
        BellIcon,
        Footer,
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        MenuAlt2Icon,
        PlusSmIcon,
        SearchIcon,
        XIcon
    },
    setup() {
        const route = useRoute();
        const mobileMenuOpen = ref(false)
        const gameStore = useGameStore();
        const games = computed(() => gameStore.games);
        const productStore = useProductStore();
        const localStore = useLocalStorageStore();
        const miscInformationStore = useMiscInformationStore();
        const searchTerm = ref();
        const searchResults = computed(() => productStore.searchResults);
        const isSearching = ref(false);
        const showNoSearchResults = ref(false);
        const lang = computed(() => miscInformationStore.countries.find(x => x.code === localStore.language)?.flag)

        const withFiltersPanel = ref(route.meta.layout === "withfilterspanel-layout");

        onBeforeMount(() => {
            productStore.clearSearchResults();
            let routeFound = false;
            games.value.map((x) => {
                if(route.params.game?.indexOf(x.slug) > -1){
                    routeFound = true;
                }
                if(!sidebarNavigation.find(y => y.name === x.name)){
                    sidebarNavigation.push({
                        name: x.name,
                        href: `/${x.slug}`,
                        image_path: x.image_path,
                        current: x.slug && route.params.game?.indexOf(x.slug) > -1
                    })
                }
            })
            if(routeFound){
                sidebarNavigation[0].current = false;
            }
        })

        watch(
            () => route.meta.layout,
            async layout => {
                withFiltersPanel.value = layout === "withfilterspanel-layout";
            }
        )

        async function searchProducts(){
            showNoSearchResults.value = false;
            if(!searchTerm.value){
                await productStore.clearSearchResults();
            }
            else if(searchTerm.value.length>2){
                isSearching.value = true;
                await productStore.searchProducts(searchTerm.value, localStore.language);
                sendFathomEvent(`SEARCHED: "${searchTerm.value}"`);
                isSearching.value = false;
                if(productStore.searchResults.length < 1){
                  showNoSearchResults.value = true;
                }
            }
        }

        async function selectProduct(i){
            const productStore = useProductStore();
            await productStore.clearSearchResults();
            searchTerm.value = undefined;
        }

        const searchProductsWithDebounce = _.debounce(searchProducts, 500);
        
        return {
            logo,
            sidebarNavigation,
            mobileMenuOpen,
            searchProductsWithDebounce,
            searchTerm,
            searchResults,
            selectProduct,
            isSearching,
            showNoSearchResults,
            lang,
            withFiltersPanel
        }
    },
}
</script>