import {defineStore} from "pinia";
import Game from "../../types/Game";
import GameApiClient from "../../api/GameApiClient";

const useGameStore = defineStore({
    id: "games",
    state: () => ({
        games: [] as Game[]
    }),
    actions: {
        async listGames() {
            if(this.games.length == 0){
                const response = await GameApiClient.listGames();
                if(response.success){
                    this.games = response.data ?? [];
                }
            }
        },
        getGameNameBySlug(slug: string){
            return this.games.find(x => x.slug === slug)?.name;
        },
        getGameBySlug(slug: string){
            return this.games.find(x => x.slug === slug);
        }
    }
});

export default useGameStore;