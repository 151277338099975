import { defineStore } from "pinia";
import CountryApiClient from "../api/CountryApiClient";
import Country from "../types/Country";
import MiscApiClient from "../api/MiscApiClient";
import SiteStatistic from "../types/SiteStatistic";

const useMiscInformationStore = defineStore({
    id: "miscInformation",
    state: () => ({
        countries: [] as Country[],
        stats: [] as SiteStatistic[]
    }),
    actions: {
        /**
         * Loads site statistics
         */
        async loadStats() {
            if(this.stats.length === 0){
                const stats = await MiscApiClient.getStats();

                this.stats = stats.data!;
            }
        },
        /**
         * Loads the countries available for selection.
         */
        async loadCountries() {
            if(this.countries.length === 0){
                const countries = await CountryApiClient.getCountries();
                this.countries = countries.data!;
            }
        }
    }
});

export default useMiscInformationStore;
