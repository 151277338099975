<template>
<div class="w-full p-4 bg-indigo-700 border-1 rounded">
    <div v-if="feedbackLeft">
        <div class="text-sm text-green-400">Thank you for the feedback!</div>
    </div>
    <div v-else class="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 justify-center sm:justify-between">
        <div class="text-sm text-gray-200">Was this link useful? <span class="text-xs">(correct stock / price?)</span></div>

        <div class="flex flex-row grow sm:grow-0 sm:gap-6 justify-around sm:justify-none">
            <ThumbUpIcon class="h-5 w-5 text-green-400 cursor-pointer" title="This link was useful!" @click="feedbackClicked(1)"/>
            <ThumbDownIcon class="h-5 w-5 text-red-400 cursor-pointer" title="This link was not useful!" @click="feedbackClicked(0)"/>
        </div>
    </div>
</div>
</template>
<script lang="ts">
import {defineComponent, ref} from "vue";
import {ThumbUpIcon, ThumbDownIcon} from "@heroicons/vue/outline";
import {sendFathomEvent} from "../../fathom/fathomUtils";

export default defineComponent({
    name: "PriceFeedbackForm",
    components: {
        ThumbUpIcon,
        ThumbDownIcon
    },
    emits:[
        'feedbackGiven'
    ],
    props:{
        productPrice: {
            type: Object,
            required:true
        }
    },
    setup(props, context){

        const feedbackLeft = ref(false);

        const feedbackClicked = function(score: number){
            context.emit('feedbackGiven', {
                "product_price_id": props.productPrice.id,
                "siteName": props.productPrice.site.slug,
                "score": score
            });
            sendFathomEvent(`FEEDBACK: ${score}`)
            feedbackLeft.value = true;
        }

        return {
            feedbackLeft,
            feedbackClicked
        }
    }
})
</script>