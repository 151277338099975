<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" :content="productName" />
        <meta property="og:description" :content="`Compare pricing and see availability for product: ${productName}.`" />
        <meta property="og:image" :content="productImagePath" />
    </teleport>
    <LoadingSpinner v-show="isLoading"></LoadingSpinner>
    <Header
        :breadcrumbs="breadcrumbs"/>
    <div v-if="!isLoading" class="bg-white dark:bg-gray-800 mb-10">
        <div class="py-4 pl-2 pr-2">
            <div class="lg:grid lg:grid-cols-6 lg:gap-x-8 lg:items-start">

                <div class="flex flex-col gap-2 lg:col-span-2"
                    :class="{
                        'lg:col-span-2': product.sub_category,
                        'lg:col-span-3': !product.sub_category
                     }">
                    <h1 class="lg:hidden text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-200">{{ product.name }}</h1>
                    <img
                        v-lazy="{src: product.image_path, loading: loadingGif}"
                        :alt="`Image for ${product.name}`"
                        class="w-full h-full object-center object-cover sm:rounded-lg"
                    />
                  <ShareBar
                    :networks="['facebook', 'twitter', 'reddit', 'whatsapp']"
                    :title="`TCG Compare - ${product.name}`"
                    :url="pageUrl"
                    :hashtags="[product.category.game.name.replace(' ' , '')]"
                  />
                    <ProductMeta v-show="Object.keys(product.meta ?? {}).length" :meta="product.meta ?? {}"/>
                </div>

                <!-- Product info -->
                <div class="mt-10 sm:mt-6 lg:mt-0 lg:col-span-3">
                    <h1 class="hidden lg:block text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-200">{{ product.name }}</h1>

                    <div class="mt-3">
                        <ProductPriceList
                            :product="product"
                            :countries="countries"
                            :tab="countryCode"
                            @on-country-code-change="changeCountry"
                            @product-link-clicked="productLinkClicked"
                            @product-feedback-clicked="productFeedbackReceived"
                        />
                    </div>

                </div>
                <div v-if="product.sub_category" class="mt-10 sm:mt-6 lg:mt-0 lg:col-span-1 lg:h-[65vh] flex flex-col">
                    <SubCategoryPanel :sub-category="product.sub_category" />
                </div>
            </div>
        </div>
        <AdWrapper ad-type="tcgplayer-wide" />
        <PriceHistoryChart
            v-if="!isChartLoading"
            :data="chartData"
            :sites="sites"
            :stats="priceStats"
        />
        <div class="min-h-full dark:text-gray-100 p-2" v-else>Loading Price History...</div>
    </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import useProductStore from "../useProductStore";
import ProductPriceList from "../components/ProductPriceList.vue";
import Header from "../../../components/Header.vue";
import useTitleManager from "../../../utils/useTitleManager";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import loadingGif from "/loading.gif";
import PriceHistoryChart from "../components/PriceHistoryChart.vue";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";
import productApiClient from "../../../api/ProductApiClient";
import MoneyUtils from "../../../utils/MoneyUtils";
import ProductMeta from "../components/ProductMeta.vue";
import SubCategoryPanel from "../components/SubCategoryPanel.vue";
import ShareBar from "../components/ShareBar.vue";
import AdWrapper from "../../../components/AdWrapper.vue";

export default {
    components: {
      AdWrapper,
        ShareBar,
        SubCategoryPanel,
        ProductMeta,
        PriceHistoryChart,
        LoadingSpinner,
        Header,
        ProductPriceList,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const productStore = useProductStore();
        const localStorageStore = useLocalStorageStore();
        const { setTitlePrefix } = useTitleManager();

        const countries = localStorageStore.getAvailableLanguages;
        const isLoading = ref(true);
        const isChartLoading = ref(true);
        const countryCode = ref(localStorageStore.language);
        const product = computed(() => productStore.activeProduct);
        const productName = ref();
        const productImagePath = ref();
        const sites = computed(() => productStore.sites);
        const productPriceHistory = computed( () => productStore.activeProductPriceHistory);
        const chartData = ref([]);
        const priceStats = ref({"lowest":0, "average":0, "highest":0});

        const breadcrumbs = computed(() => productStore.getActiveProductBreadcrumbs());

        const setup = async (clear) => {
            isLoading.value = true;
            isChartLoading.value = true;
            if(clear){
                await productStore.clearActiveProduct();
                await productStore.getProduct(route.params.game, route.params.categorySlug, route.params.productSlug);
            }

            let priceSt = {};
            await Promise.all([
                chartData.value = await productStore.getPricingChartData(countryCode.value),
                priceSt = await productStore.getProductPriceStats(
                    route.params.game,
                    route.params.categorySlug,
                    route.params.productSlug,
                    countryCode.value
                )
            ]);

            priceStats.value = {
                lowest: MoneyUtils.getDisplayValue(countryCode.value, MoneyUtils.convertToPounds(priceSt.lowest)),
                average: MoneyUtils.getDisplayValue(countryCode.value, MoneyUtils.convertToPounds(priceSt.average)),
                highest: MoneyUtils.getDisplayValue(countryCode.value, MoneyUtils.convertToPounds(priceSt.highest))
            };

            isLoading.value = false;
            isChartLoading.value = false;
            productName.value = product.value?.name;
            productImagePath.value = product.value?.image_path;
            setTitlePrefix(product.value?.name);
        }
        onBeforeMount(async() => {
            if(route.params.lang.length > 0 && localStorageStore.getAvailableLanguages.indexOf(route.params.lang.toUpperCase())>-1){
                let newLangCode = route.params.lang.toUpperCase();
                if(newLangCode !== localStorageStore.language){
                    localStorageStore.setLanguage(newLangCode)
                }
                countryCode.value = newLangCode;
            }
            await setup(true);
        })

        watch(() => localStorageStore.language, async () => {
            await changeCountry(localStorageStore.language);
        });

        watch(() => route.params.productSlug, async () => {
            if(route.name === "game-product"){
                await setup(true)
            }
        });

        const changeCountry = async (cc) => {
            isChartLoading.value = true;


            let priceSt = {};
            countryCode.value = cc;
            await router.replace({
                name: "game-product",
                params: {
                    ...router.currentRoute.value.params,
                    lang: cc
                }
            });
            await Promise.all([
                chartData.value = await productStore.getPricingChartData(cc),
                priceSt = await productStore.getProductPriceStats(
                    route.params.game,
                    route.params.categorySlug,
                    route.params.productSlug,
                    cc
                )
            ]);

            priceStats.value = {
                lowest: MoneyUtils.getDisplayValue(cc, MoneyUtils.convertToPounds(priceSt.lowest)),
                average: MoneyUtils.getDisplayValue(cc, MoneyUtils.convertToPounds(priceSt.average)),
                highest: MoneyUtils.getDisplayValue(cc, MoneyUtils.convertToPounds(priceSt.highest))
            };
            isChartLoading.value = false;
        }

        const productLinkClicked = (data) => {
            productApiClient.productOut(route.params.game, product.value.category.slug, product.value.slug, data.productPriceId, data.site);
        }

        const productFeedbackReceived = (details) => {
            productApiClient.productPriceFeedback(route.params.game, product.value.category.slug, product.value.slug, details["product_price_id"], details["score"], details['siteName']);
        }

        return {
            isLoading,
            isChartLoading,
            breadcrumbs,
            product,
            productName,
            productImagePath,
            productPriceHistory,
            loadingGif,
            chartData,
            sites,
            countries,
            countryCode,
            changeCountry,
            productLinkClicked,
            productFeedbackReceived,
            priceStats,
            pageUrl: window.location.href
        }
    },
}
</script>