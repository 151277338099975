<template>
    <div v-if="settingUpApp"
         class="flex flex-col justify-center spacing flex overflow-hidden bg-white dark:bg-gray-800 p-4 h-full">
            <LoadingSpinner />
            <div class="text-center text-xl mt-2">loading...</div>
    </div>
    <component v-else :is="layout" />
    <NotificationWrapper />
    <ErrorHandler />
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import AuthApiClient from "./api/AuthApiClient";
import useGameStore from "./modules/games/useGameStore";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import {installFathom} from "./modules/fathom/fathomUtils";
import useMiscInformationStore from "./utils/useMiscInformationStore";
import NotificationWrapper from "./components/NotificationWrapper.vue";
import ErrorHandler from "./components/ErrorHandler.vue";
import {installMetricoolTracker} from "./modules/metricool/metricoolUtils";
import EmptyLayout from "./layouts/Empty.vue";

const defaultLayout = "default";

export default defineComponent({
    name: 'App',
    components: {
      ErrorHandler,
      NotificationWrapper,
      LoadingSpinner,
      EmptyLayout
    },
    setup(){
        const route = useRoute();
        const gameStore = useGameStore();
        const miscStore = useMiscInformationStore();
        installFathom();
        installMetricoolTracker();

        const layout = computed(() => {
            return route.meta?.noWrapper ? "empty-layout" : defaultLayout + "-layout"
        });

        let settingUpApp = ref(true);

        onBeforeMount(async () => {
            await AuthApiClient.getCSRFCookie();

            if(route.name !== 'out'){
                await Promise.all([
                    gameStore.listGames(),
                    miscStore.loadCountries()
                ]);
            }

            settingUpApp.value = false;
        })

        return {
            layout,
            settingUpApp
        }
    }
})
</script>

<style>
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
