<template>
    <div v-show="!hasDismissedBanner" id="bottom-banner" tabindex="-1"
         class="fixed bottom-0 start-0 z-50 flex justify-between w-full px-2 py-4 border-t border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div class="flex items-center mx-auto">
            <p class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
            <span
                class="inline-flex p-1 me-3 bg-indigo-700 rounded-full dark:bg-gray-600 w-8 h-8 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     width="40" height="40"
                     viewBox="0 0 128 128"
                     style="fill:lightgrey;"><path d="M45.23,57.2c-6.16,0-11.17,5.6-11.17,12.48s5,12.47,11.17,12.47,11.16-5.59,11.16-12.47S51.38,57.2,45.23,57.2Zm0,21c-4,0-7.17-3.8-7.17-8.47s3.21-8.48,7.17-8.48,7.16,3.8,7.16,8.48S49.18,78.15,45.23,78.15Z"/><path d="M121.83,59.58a156.78,156.78,0,0,0-11.52-31,2.1,2.1,0,0,0-.71-.77,87.08,87.08,0,0,0-15.23-7.17C84.55,17.07,79.91,17,79.72,17a2,2,0,0,0-2,1.72l-.6,4.17a133.14,133.14,0,0,0-26.28,0l-.6-4.17a2,2,0,0,0-2-1.72c-.19,0-4.83,0-14.65,3.61A87.08,87.08,0,0,0,18.4,27.81a2.1,2.1,0,0,0-.71.77,156.72,156.72,0,0,0-11.52,31C1,80.46,0,90.91,0,91.34a2,2,0,0,0,.49,1.5,55.2,55.2,0,0,0,18.2,12.74A76.32,76.32,0,0,0,38.48,111a2,2,0,0,0,1.92-1l5.4-9.25A105.08,105.08,0,0,0,64,102.24a105.08,105.08,0,0,0,18.2-1.51L87.6,110a2,2,0,0,0,1.72,1h.2a76.32,76.32,0,0,0,19.78-5.38,55.2,55.2,0,0,0,18.2-12.74,2,2,0,0,0,.49-1.5C128,90.91,127.05,80.46,121.83,59.58Zm-14.06,42.31a76.76,76.76,0,0,1-17.39,4.92l-4.08-7c4.68-1.24,14.42-4.46,21.83-11.2a2,2,0,1,0-2.69-3c-9,8.23-22.46,10.84-22.6,10.87h-.06A96.59,96.59,0,0,1,64,98.24a96.59,96.59,0,0,1-18.78-1.7h-.06c-.14,0-13.55-2.64-22.6-10.87a2,2,0,1,0-2.69,3c7.41,6.74,17.15,10,21.83,11.2l-4.08,7a76.08,76.08,0,0,1-17.39-4.92A52.24,52.24,0,0,1,4.08,90.8c.33-2.91,1.68-13.07,6-30.24A156.25,156.25,0,0,1,21,30.92,88.17,88.17,0,0,1,35,24.4a61.35,61.35,0,0,1,11.58-3.19l.35,2.39c-4,1-13.85,3.86-21.65,9.53a2,2,0,1,0,2.36,3.23c8.82-6.41,21-9.06,21.86-9.25A118.4,118.4,0,0,1,64,26.27a117.64,117.64,0,0,1,14.51.84c.91.19,13,2.83,21.86,9.25a2,2,0,1,0,2.36-3.23c-7.8-5.67-17.61-8.52-21.65-9.53l.35-2.39A61.75,61.75,0,0,1,93,24.4a88.17,88.17,0,0,1,14,6.52A156.25,156.25,0,0,1,118,60.56c4.29,17.17,5.64,27.33,6,30.24A52.24,52.24,0,0,1,107.77,101.89Z"/><path d="M82.77,57.2c-6.15,0-11.16,5.6-11.16,12.48s5,12.47,11.16,12.47,11.17-5.59,11.17-12.47S88.93,57.2,82.77,57.2Zm0,21c-4,0-7.16-3.8-7.16-8.47s3.21-8.48,7.16-8.48,7.17,3.8,7.17,8.48S86.73,78.15,82.77,78.15Z"/></svg>
                <span class="sr-only">Discord</span>
            </span>
                <span>{{bannerMessage}}
                    <a href="https://discord.gg/UzRT99Zp7H" title="Join our Discord community" target="_blank"
                        class="flex items-center ms-0 text-sm font-medium text-indigo-700 md:ms-1 md:inline-flex dark:text-indigo-500 hover:underline">
                    Join our Discord community
                    <svg class="w-3 h-3 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg></a></span>
            </p>
        </div>
        <div class="flex items-center">
            <button type="button" @click="dismissBanner"
                    class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close banner</span>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import useLocalStorageStore from "../../utils/useLocalStorageStore";

export default defineComponent({
    name: "BottomBanner",
    props: {},
    setup() {
        const localStorageStore = useLocalStorageStore();
        const hasDismissedBanner = computed(() => localStorageStore.hasDismissedBanner);

        const dismissBanner = () => {
            localStorageStore.setDismissedBanner();
        }
        const targetDate = new Date('2024-12-27T20:00:00');
        const now = new Date();

        const bannerMessage = computed(() => {
            if (now > targetDate) {
                return "Want to see new products and price alerts in real time?";
            }
            return "🎁 XMAS 2024 Giveaway Currently Live! 151 booster bundles up for grabs! (22 total prizes), to be in with a chance of winning";
        });

        return {
            bannerMessage,
            hasDismissedBanner,
            dismissBanner
        }
    }
})

</script>