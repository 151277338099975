import axios from "axios";
import { ApiAsyncWrapper, ApiResponseWrapper } from "./utils/ApiAsyncWrapper";
import Product from "../types/Product";
import PaginatedResponse from "../types/PaginatedResponse";
import {ProductListOptions} from "../types/ProductListOptions";
import ProductPrice from "../types/ProductPrice";
import ProductBackInStock from "../types/ProductBackInStock";
import ProductAvgPrices from "../types/ProductAvgPrices";
import ProductDeal from "../types/ProductDeal";

function listProductsInCategory(gameSlug: string, categorySlug: string, options: ProductListOptions): Promise<ApiResponseWrapper<PaginatedResponse<Product[]>>> {
    const params = new URLSearchParams(options);
    let url = `/${gameSlug}/category/${categorySlug}`;
    if(params.toString().length>0){
        url = url+`?${params.toString()}`;
    }
    return ApiAsyncWrapper(axios.get(url));
}

function listProductsInSubCategory(gameSlug: string, subCategorySlug: string, options: ProductListOptions): Promise<ApiResponseWrapper<PaginatedResponse<Product[]>>> {
    const params = new URLSearchParams(options);
    let url = `/${gameSlug}/sub-category/${subCategorySlug}`;
    if(params.toString().length>0){
        url = url+`?${params.toString()}`;
    }
    return ApiAsyncWrapper(axios.get(url));
}

function getProductByCategoryAndProductSlug(gameSlug: string, categorySlug: string, productSlug: string): Promise<ApiResponseWrapper<Product>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}`));
}

function getProductPriceHistory(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string): Promise<ApiResponseWrapper<ProductPrice[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/prices/${countryCode}/history`));
}

function getProductPrices(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string): Promise<ApiResponseWrapper<ProductPrice[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/prices/${countryCode}`));
}

function getProductPrice(gameSlug: string, categorySlug: string, productSlug: string, productPriceId: string): Promise<ApiResponseWrapper<ProductPrice>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/price/${productPriceId}`));
}


function getCardmarketProductPrices(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string): Promise<ApiResponseWrapper<ProductPrice[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/cardmarket-prices/${countryCode}`));
}

function searchProducts(searchTerm: string, countryCode: string): Promise<ApiResponseWrapper<Product[]>> {
    return ApiAsyncWrapper(axios.get(`/search?searchTerm=${searchTerm}&countryCode=${countryCode}`));
}

function productOut(gameSlug: string, categorySlug: string, productSlug: string, productPriceId: number, siteName: string): Promise<ApiResponseWrapper<unknown>> {
    let params: string = productPriceId.toString();
    if(siteName === "Cardmarket"){
        params+= "/cardmarket";
    }
    return ApiAsyncWrapper(axios.post(`/${gameSlug}/${categorySlug}/${productSlug}/out/${params}`));
}

function getLatest(options?: ProductListOptions): Promise<ApiResponseWrapper<Product[]>> {
    const params = new URLSearchParams(options);
    let url = `/products/latest`;
    if(params.toString().length>0){
        url = url+`?${params.toString()}`;
    }
    return ApiAsyncWrapper(axios.get(url));
}

function getBackInStock(options?: ProductListOptions): Promise<ApiResponseWrapper<ProductBackInStock[]>> {
    const params = new URLSearchParams(options);
    let url = `/products/back-in-stock`;
    if(params.toString().length>0){
        url = url+`?${params.toString()}`;
    }
    return ApiAsyncWrapper(axios.get(url));
}

function getAvgPrice(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string): Promise<ApiResponseWrapper<ProductAvgPrices>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/prices/${countryCode}/average`));
}

function getEbayPrices(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string): Promise<ApiResponseWrapper<ProductPrice[]>> {
    return ApiAsyncWrapper(axios.get(`/${gameSlug}/${categorySlug}/${productSlug}/ebay-prices/${countryCode}`));
}

function getDeals(options?: ProductListOptions): Promise<ApiResponseWrapper<ProductDeal[]>> {
    const params = new URLSearchParams(options);
    let url = `/products/deals`;
    if(params.toString().length>0){
        url = url+`?${params.toString()}`;
    }
    return ApiAsyncWrapper(axios.get(url));
}

function productPriceFeedback(gameSlug: string, categorySlug: string, productSlug: string, productPriceId: number, score: number, siteName: string): Promise<ApiResponseWrapper<unknown>> {
    let params: string = productPriceId+`/${score}`;
    if(siteName === "cardmarket"){
        params+= "/cardmarket";
    }
    return ApiAsyncWrapper(axios.post(`/${gameSlug}/${categorySlug}/${productSlug}/feedback/${params}`));
}

export default {
    listProductsInCategory,
    listProductsInSubCategory,
    getProductByCategoryAndProductSlug,
    getProductPrices,
    getProductPrice,
    getProductPriceHistory,
    searchProducts,
    productOut,
    getLatest,
    getBackInStock,
    getAvgPrice,
    getDeals,
    getEbayPrices,
    getCardmarketProductPrices,
    productPriceFeedback
}
